import { useTranslation } from "react-i18next";

const Warning = () => {
  const { t } = useTranslation();

  return (
    <div>
      {t("cap-832_1")}{" "}
      <span
        className="tw-underline tw-cursor-pointer"
        onClick={() => window.open(t("cap-832_2"), "_blank")}
      >
        {t("cap-832_3")}
      </span>
    </div>
  );
};

export default Warning;
