import * as Sentry from "@sentry/browser";
import Config from "config";
import saveAuthToken from "./saveAuthToken";

type FetchNewTokenArgs = { code: string } | { refreshToken: string };

window.__isFetchingAuthToken = false;

async function fetchAuthTokens(
  args: FetchNewTokenArgs
): Promise<true | string> {
  try {
    const res = await fetch(
      `${Config.casaconnectUrl}/realms/casasoft/protocol/openid-connect/token`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body:
          "code" in args
            ? new URLSearchParams({
                code: args.code,
                grant_type: "authorization_code",
                client_id: "casaone-app",
                redirect_uri: `${Config.apiUrl}/dev/redirect-to-company-app`,
              })
            : new URLSearchParams({
                refresh_token: args.refreshToken,
                grant_type: "refresh_token",
                client_id: "casaone-app",
              }),
      }
    );
    const json = await res.json();
    if (res.status === 400) {
      return "unauthorized";
    }

    if (
      typeof json.access_token === "string" &&
      typeof json.expires_in === "number" &&
      typeof json.refresh_token === "string" &&
      typeof json.refresh_expires_in === "number"
    ) {
      saveAuthToken({
        accessToken: json.access_token,
        accessTokenExpire: json.expires_in,
        refreshToken: json.refresh_token,
        refreshTokenExpire: json.refresh_expires_in,
      });
      return true;
    } else {
      throw new Error("Expected a proper object of tokens");
    }
  } catch (err) {
    const error = new Error(
      `An auth token failed to fetch without a validation handler`
    );
    if (err instanceof Error) {
      Sentry.captureException(error);
      return "unknown";
    } else {
      // eslint-disable-next-line no-console
      console.error(err);

      Sentry.captureException(error);
      return "unknown";
    }
  } finally {
    window.__isFetchingAuthToken = false;
  }
}

export default fetchAuthTokens;
