import { axiosInstance } from "utilities/axios";
import Config from "config";
import * as Sentry from "@sentry/browser";
import { CaContractShape } from "entities/caContract/types";
import { UserShape } from "api/entities/user/types";
import { AvatarShape, ContactShape } from "entities/contact/types";
import { CompanyShape } from "api/entities/csiamCompany/types";
import { Role } from "./type-helpers/userRoles";

export interface FetchProfileCasaoneUserShape {
  roles: Role[];
  firstCheckIn: UserShape["firstCheckIn"];
  username: UserShape["username"];
  filterPresets: UserShape["filterPresets"] | []; // empty array - mysql bug
  contact: {
    id: ContactShape["id"];
    lang: ContactShape["lang"];
    email: ContactShape["email"];
    gender: ContactShape["gender"];
    salutationTemplate: ContactShape["salutationTemplate"];
    function: ContactShape["function"];
    firstName: ContactShape["firstName"];
    lastName: ContactShape["lastName"];
    displayName: ContactShape["displayName"];
    dob: ContactShape["dob"];
    _embedded: {
      avatar: null | {
        id: AvatarShape["id"];
        path: AvatarShape["path"];
        originalFilename: AvatarShape["originalFilename"];
        alt?: AvatarShape["alt"];
        size: AvatarShape["size"];
        width: AvatarShape["width"];
        height: AvatarShape["height"];
        crop1x1?: AvatarShape["crop1x1"];
        mimeType: AvatarShape["mimeType"];
        viewGroup?: AvatarShape["viewGroup"];
      };
    };
  };
}

// based on api/vendor/zfcampus/zf-mvc-auth/src/Identity/AuthenticatedIdentity.php
// extended with api/module/Client/src/V1/Rpc/FetchProfile/FetchProfileController.php
export interface FetchProfileAPIResponse {
  id: number;
  company?: CompanyShape & {
    contracts?: CaContractShape[];
  };
  casaoneUser: FetchProfileCasaoneUserShape | null;
  apiCompanySection: string;
}

const fetchProfileAPI = async (): Promise<FetchProfileAPIResponse> => {
  const axiosResponse = await axiosInstance.get<FetchProfileAPIResponse>(
    `${Config.apiUrl}/${Config.customerKey}/api/v2/fetch-profile`
  );
  if (axiosResponse.status === 200) {
    return axiosResponse.data;
  } else if (axiosResponse.status === 401) {
    throw new Error("unauthorized");
  } else {
    // eslint-disable-next-line no-console
    console.error(axiosResponse);
    throw new Error("unknown error");
  }
};

export interface UpdateGatewayAPIResponse {
  validation: string[];
  gateway: {
    file_to_send: string;
    curlResponse: string;
    httpcode: number;
    curlInfo: {
      url: string;
      content_type: string;
      http_code: number;
      header_size: number;
      request_size: number;
      filetime: number;
      ssl_verify_result: number;
      redirect_count: number;
      total_time: number;
      namelookup_time: number;
      connect_time: number;
      pretransfer_time: number;
      size_upload: number;
      size_download: number;
      speed_download: number;
      speed_upload: number;
      download_content_length: number;
      upload_content_length: number;
      starttransfer_time: number;
      redirect_time: number;
      redirect_url: string;
      primary_ip: string;
      certinfo: [];
      primary_port: number;
      local_ip: string;
      local_port: number;
    };
  };

  // ERROR
  status?: number;
  message?: string;
}

const updateGatewayAPI = async (
  companyId: string | undefined,
  force: boolean | undefined
) => {
  let url = companyId
    ? `${Config.api2Url}/company/${companyId}/update-gateway`
    : `${Config.api2Url}/company/${Config.customerKey}/update-gateway`;

  if (force) {
    url = `${url}?force=1`;
  }
  try {
    const res = await axiosInstance.post<UpdateGatewayAPIResponse>(url);
    return res.data;
  } catch (err) {
    const error = new Error(`Gateway Sync 500: ${Config.customerKey}`);
    Object.assign(error, {
      error: err,
    });
    throw error;
  }
};

export interface EventTypesListResponseShape {
  eventType: null | string;
  baseType: string;
  contents: {
    lang: string;
    label: string;
  }[];
  context: string[] | null;
  group?: {
    contents: {
      lang: string;
      label: string;
    }[];
    name: string;
  };
  icon: string;
  iconSolid: string;
  iconRegular: string;
  defaultDuration: number;
  options: any[];
}

interface FetchEventTypesAPIResponse {
  eventTypes?: EventTypesListResponseShape[];
}

const fetchEventTypesAPI = (): Promise<FetchEventTypesAPIResponse> =>
  axiosInstance
    .get(`${Config.api2Url}/company/${Config.customerKey}/event-types-combined`)
    .then((response) => response.data)
    .catch((err) => {
      const error = new Error(
        `event-types-combined error: ${Config.customerKey}`
      );
      Object.assign(error, {
        error: err,
      });
      Sentry.captureException(error);
      throw err;
    });

export { fetchProfileAPI, updateGatewayAPI, fetchEventTypesAPI };
