import { Alert } from "@casasoft/styleguide/components/helpers-ux";
import { AlertProps } from "@casasoft/styleguide/components/helpers-ux/Alert";

interface MessageSettingsUpdatedAlertProps {
  text: string;
  color?: AlertProps["color"];
}

export const MessageSyncErrorAlert = ({
  text,
  color = "warning",
}: MessageSettingsUpdatedAlertProps) => {
  return (
    <Alert color={color}>
      <div>{text}</div>
    </Alert>
  );
};
